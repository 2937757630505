import React from "react";

export default function YoutubeIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      height="512"
      width="512"
      className={className}
    >
      <path
        fill="currentColor"
        d="M506.9 153.6s-5-35.3-20.4-50.8c-19.5-20.4-41.3-20.5-51.3-21.7-71.6-5.2-179.1-5.2-179.1-5.2h-.2s-107.5 0-179.1 5.2c-10 1.2-31.8 1.3-51.3 21.7-15.4 15.5-20.3 50.8-20.3 50.8S.1 195 .1 236.5v38.8c0 41.4 5.1 82.9 5.1 82.9s5 35.3 20.3 50.8C45 429.4 70.6 428.7 82 430.9c41 3.9 174.1 5.1 174.1 5.1s107.6-.2 179.2-5.3c10-1.2 31.8-1.3 51.3-21.7 15.4-15.5 20.4-50.8 20.4-50.8s5.1-41.4 5.1-82.9v-38.8c-.1-41.4-5.2-82.9-5.2-82.9zM203.1 322.4V178.5l138.3 72.2-138.3 71.7z"
      />
    </svg>
  );
}
