import React, { forwardRef } from "react";
import classNames from "lib/classNames";

const Button = forwardRef(
  (
    {
      children,
      theme = "primary",
      as = "button",
      size = "default",
      block = false,
      inverse = false,
      ...props
    },
    ref
  ) => {
    const Tag = as;

    const themes = {
      primary: {
        default:
          "border-primary bg-primary text-white hover:border-primary-dark hover:bg-primary-dark",
        inverse:
          "border-primary bg-white text-default hover:bg-primary hover:text-white",
      },
      "primary-dark": {
        default:
          "border-primary-dark bg-primary-dark text-white hover:border-primary-dark hover:bg-primary-dark",
        inverse:
          "border-primary-dark bg-white text-default hover:bg-primary-dark hover:text-white",
      },
    };

    const sizes = {
      default: "py-4 px-6 lg:py-5 lg:px-7",
      small: "py-4 px-6",
    };

    return (
      <Tag
        ref={ref}
        className={classNames(
          "group rounded-md border font-important font-medium no-underline cursor-pointer transition-colors duration-500",
          block ? "block" : "inline-block",
          inverse ? themes[theme].inverse : themes[theme].default,
          sizes[size]
        )}
        {...props}
      >
        {children}
      </Tag>
    );
  }
);

Button.displayName = "Button";
export default Button;
