import React from "react";

export default function PenIcon({ className }) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g fill="currentColor" fillRule="nonzero">
        <path
          fillOpacity=".3"
          d="M11.158 25.79 7.8 22.794 25.295 5.848l3.358 2.995z"
        />
        <path d="M21.579 0 30 8.421 12.632 25.79 10.2 28.221H3.251L1.474 30 0 28.526l1.778-1.778.001-6.948 2.432-2.432L16.63 4.947l-2-1.999-7.6 7.6-1.473-1.474L14.632 0l3.473 3.473L21.579 0ZM4.21 20.316l-.348.346v5.475h5.472l.349-.348-5.473-5.473ZM18.105 6.421 5.685 18.842l5.473 5.473 12.42-12.421-5.473-5.473Zm3.474-3.473-2.001 1.999 5.474 5.473 2-1.999-5.473-5.473Z" />
      </g>
    </svg>
  );
}
