import React from "react";

export default function ChatIcon({ className }) {
  return (
    <svg
      width="32"
      height="30"
      viewBox="0 0 32 30"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g fill="currentColor" fillRule="evenodd">
        <path
          d="M19.223 11.372c-1.084 3.843-1.021 7.177.19 10 1.211 2.824 3.456 5.166 6.735 7.026l-8.985-.055-4.53-3.838-1.435-5.32 2.734-4.76 5.291-3.053Z"
          opacity=".3"
        />
        <path
          d="M20.933 9.767c5.643 0 10.217 4.53 10.217 10.117 0 2.08-.637 4.066-1.799 5.734l-.063.084L32 30H20.933c-5.643 0-10.218-4.53-10.218-10.116 0-5.587 4.575-10.117 10.218-10.117Zm0 2.093c-4.476 0-8.104 3.593-8.104 8.024 0 4.43 3.628 8.023 8.104 8.023l7.252-.001-1.479-2.343.484-.581a7.937 7.937 0 0 0 1.846-5.098c0-4.431-3.628-8.024-8.103-8.024ZM14.59 0c5.271 0 10.007 2.966 12.311 7.558l-1.893.931c-1.95-3.886-5.957-6.396-10.418-6.396-6.421 0-11.627 5.154-11.627 11.512 0 2.707.946 5.268 2.649 7.314l.483.582-2.282 3.614 5.14.001v2.093H0l3.51-5.562-.134-.18a13.452 13.452 0 0 1-2.517-7.359l-.009-.503C.85 6.09 7.002 0 14.59 0Z"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}
