import React from "react";

export default function TransportIcon({ className }) {
  return (
    <svg
      width="27"
      height="28"
      viewBox="0 0 27 28"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="m1.393 0 3.571 3.496.001 17.567a3.581 3.581 0 0 1 2.49 2.437h16.63L27 26.355l-1.393 1.364-2.338-2.29-15.814.002C7.025 26.913 5.632 28 3.979 28 1.985 28 .37 26.417.37 24.465c0-1.618 1.11-2.982 2.625-3.402V4.295L0 1.364 1.393 0Zm2.586 22.858c-.906 0-1.641.72-1.641 1.607s.735 1.607 1.641 1.607c.907 0 1.642-.72 1.642-1.607 0-.888-.735-1.607-1.642-1.607Zm19.37-16.07v13.499H8.247V6.788H23.35Zm-1.97 1.929H10.217v9.641h11.162V8.717Zm-2.298 5.785v1.928h-3.94v-1.928h3.94Z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
}
