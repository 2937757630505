import React from "react";
import FooterMenu from "components/Menu/FooterMenu";
import Branding from "components/Branding";
import BasicBlock from "components/Block/BasicBlock";
import MainMenuFlat from "components/Menu/MainMenuFlat";
import SocialNetworksMenu from "components/Menu/SocialNetworksMenu";
import useTranslation from "next-translate/useTranslation";

export default function Footer({ globals }) {
  const { t } = useTranslation("common");

  return (
    <footer className="relative bg-secondary-light">
      <div className="relative">
        <div className="container mx-auto px-4">
          <div className="flex flex-col lg:flex-row gap-8 py-24">
            <div className="flex flex-col gap-8 lg:w-1/3">
              <Branding />

              {globals?.blocks?.infos && (
                <BasicBlock data={globals.blocks.infos} />
              )}

              {globals?.menus?.socialNetworks && (
                <SocialNetworksMenu menu={globals.menus.socialNetworks} />
              )}
            </div>

            <div className="lg:w-2/3">
              <MainMenuFlat menu={globals.menus.main} />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-8 border-t border-secondary py-10">
            <div className="flex-1">
              {globals?.menus?.footer && (
                <FooterMenu menu={globals.menus.footer} />
              )}
            </div>

            <a
              href="https://www.isics.fr"
              target="blank"
              className="text-sm text-primary font-important hover:text-primary-dark transition-colors"
            >
              {t("copyright.text")}
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
