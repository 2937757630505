import React from "react";

export default function BookmarkIcon({ className }) {
  return (
    <svg
      width="22"
      height="29"
      viewBox="0 0 22 29"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g fill="currentColor" fillRule="evenodd">
        <path opacity=".3" d="M1 6h7v16l-7 4.264z" />
        <path
          d="M17.188 4.758V29l-8.594-6.953L0 29V4.758h17.188Zm-2.063 2.04H2.062v17.899l6.532-5.283 6.531 5.284v-17.9ZM22 0v22.77h-2.063V2.04H5.845V0H22Z"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}
