import React from "react";

export default function ThumbUpIcon({ className }) {
  return (
    <svg
      width="30"
      height="27"
      viewBox="0 0 30 27"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g fill="currentColor" fillRule="nonzero">
        <path opacity=".3" d="M25.811 25.962H17.53l3.744-16.195h7.935z" />
        <path d="M11.282 0a6.022 6.022 0 0 1 6.021 6.023v2.285H30L26.107 27H0V11.77h5.797L9.26 0h2.023ZM5.537 13.846h-3.46v11.077h3.46V13.846Zm5.745-11.77h-.471l-3.198 10.88v11.967h16.804l3.029-14.538h-12.22V6.023a3.946 3.946 0 0 0-3.944-3.946Z" />
      </g>
    </svg>
  );
}
