import React from "react";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";
import NextLink from "components/NextLink";

export default function MainMenuFlat({ menu }) {
  const { lang } = useTranslation();

  return (
    <ul className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8">
      {menu.map((item) => {
        if (item.langcode !== lang) return null;
        const hasDropdown = item.items?.length > 0;

        if (item.url === "/" || item.url === "/en") {
          return null;
        }

        return (
          <li key={item.id} className="flex flex-col gap-4">
            <NextLink
              href={item.url}
              className="font-important font-medium text-primary uppercase no-underline hover:text-primary-dark transition-colors"
            >
              {item.title}
            </NextLink>
            {hasDropdown && (
              <ul className="flex flex-col gap-4">
                {item.items.map((subItem) => {
                  if (subItem.langcode !== lang) return null;

                  return (
                    <li key={subItem.id}>
                      <NextLink
                        href={subItem.url}
                        className="font-important no-underline hover:text-primary transition-colors"
                      >
                        {subItem.title}
                      </NextLink>
                    </li>
                  );
                })}
              </ul>
            )}
          </li>
        );
      })}
    </ul>
  );
}
