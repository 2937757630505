import React from "react";

export default function SearchIcon({ className }) {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.375 0a7.375 7.375 0 0 1 5.874 11.835l3.458 3.458a1 1 0 0 1-1.414 1.414l-3.458-3.458A7.375 7.375 0 1 1 7.375 0Zm0 2a5.375 5.375 0 1 0 0 10.75 5.375 5.375 0 0 0 0-10.75Z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
}
