import React, { Fragment, useEffect, useState } from "react";
import dynamic from "next/dynamic";
import useTranslation from "next-translate/useTranslation";
import { MenuIcon } from "@heroicons/react/outline";
import { Dialog, Transition } from "@headlessui/react";
import { SearchIcon, UserIcon } from "components/Icons";
import MainMenu from "components/Menu/MainMenu";
import LocalesDropdown from "components/LocalesDropdown";
import classNames from "lib/classNames";

const NavbarDialog = dynamic(() => import("components/NavbarDialog"));

const NAVBAR_BREAKPOINT = 1280;

export default function Navbar({ globals, search, searchHandler }) {
  const { t } = useTranslation("common");
  const [isOpen, setIsOpen] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    setIsDesktop(
      typeof window !== "undefined" && window.innerWidth >= NAVBAR_BREAKPOINT
    );

    const handleResize = () => {
      setIsDesktop(window.innerWidth >= NAVBAR_BREAKPOINT);
    };

    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);
    }
  }, [setIsDesktop]);

  useEffect(() => {
    if (isDesktop) {
      setIsOpen(false);
    }
  }, [isDesktop, setIsOpen]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.remove("overflow-y-visible");
      document.body.classList.add("overflow-y-hidden");
    } else {
      document.body.classList.remove("overflow-y-hidden");
      document.body.classList.add("overflow-y-visible");
    }
  }, [isOpen]);

  return isDesktop ? (
    <nav className="flex h-full">
      {globals?.menus?.main && <MainMenu menu={globals.menus.main} depth={2} />}

      <button
        type="button"
        className={classNames(
          "flex items-center text-primary hover:text-primary-dark transition-colors",
          search && "bg-secondary-light"
        )}
        onClick={() => searchHandler((prevSearch) => !prevSearch)}
      >
        <span className="border-x border-secondary px-6">
          <SearchIcon className="h-4 w-4" aria-hidden="true" />
          <span className="sr-only">{t("search.title")}</span>
        </span>
      </button>

      <a className="flex items-center gap-3 px-6 font-important font-medium text-sm uppercase hover:text-primary transition-colors" href="https://www.metropack.net/clients/">
        <UserIcon className="h-4 w-4 text-primary" aria-hidden="true" />
        <span className="sr-only 2xl:not-sr-only">{t("private.title")}</span>
      </a>

      <LocalesDropdown />
    </nav>
  ) : (
    <nav className="flex h-full">
      <button
        type="button"
        className={classNames(
          "flex items-center text-primary transition-colors",
          search && "bg-secondary-light"
        )}
        onClick={() => searchHandler((prevSearch) => !prevSearch)}
      >
        <span className="block px-6">
          <SearchIcon className="h-5 w-5" aria-hidden="true" />
          <span className="sr-only">{t("search.title")}</span>
        </span>
      </button>

      <LocalesDropdown />

      <button
        type="button"
        className={classNames(
          "group flex items-center hover:text-primary transition-colors",
          isOpen && "bg-secondary-light"
        )}
        onClick={() => setIsOpen(true)}
      >
        <span className="flex items-center gap-2 pl-6 border-l border-secondary">
          <MenuIcon className="h-5 w-5 text-primary" aria-hidden="true" />
          <span className="block sr-only md:not-sr-only uppercase font-important font-medium text-xs">
            Menu
          </span>
        </span>
      </button>

      <Transition as={Fragment} show={isOpen}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50"
          onClose={() => setIsOpen(false)}
        >
          <Transition.Child
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-default/70 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            className="absolute inset-y-0 right-0 left-0 py-24 px-8 bg-primary-dark text-white h-full md:left-1/4"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <NavbarDialog
              globals={globals}
              closeCallback={() => setIsOpen(false)}
            />
          </Transition.Child>
        </Dialog>
      </Transition>
    </nav>
  );
}
