import React, {
  createRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import dynamic from "next/dynamic";
import useTranslation from "next-translate/useTranslation";
import { Transition } from "@headlessui/react";
import classNames from "lib/classNames";
import { ScrollContext } from "contexts/ScrollContext";
import NodeBannerList from "components/Banner/NodeBannerList";
import Branding from "components/Branding";
import Navbar from "components/Navbar";

const FlashInfo = dynamic(() => import("components/FlashInfo"));
const SearchBar = dynamic(() => import("components/Search/SearchBar"));

export default function Header({ globals, banners, home = false }) {
  const topRef = createRef();
  const navbarRef = useRef();
  const { lang } = useTranslation("common");
  const [offset, setOffset] = useState(110);
  const { scrollDir, scrollY, threshold } = useContext(ScrollContext);
  const [showSearch, setShowSearch] = useState(false);
  const [showFlashInfo, setShowFlashInfo] = useState(false);
  const hasBanners = banners?.length > 0;

  useEffect(() => {
    if (navbarRef.current) {
      setOffset(navbarRef.current.offsetHeight);
    }
  }, []);

  useEffect(() => {
    if (navbarRef.current) {
      setOffset(navbarRef.current.offsetHeight);
    }
  }, [showFlashInfo, lang]);

  return (
    <header
      ref={topRef}
      className={classNames(
        "relative z-10 bg-white",
        hasBanners && "xl:h-screen"
      )}
      style={{
        paddingTop: `${offset}px`,
      }}
    >
      <Transition
        ref={navbarRef}
        show={
          scrollDir === "none" || scrollDir === "up"
            ? true
            : !(scrollDir === "down" && scrollY > threshold)
        }
        enter="transition-all duration-75 ease-in"
        enterFrom="-translate-y-full"
        enterTo="translate-y-0"
        leave="transition-all duration-150 ease-out"
        leaveFrom="translate-y-0"
        leaveTo="-translate-y-full"
        className="fixed top-0 inset-x-0 z-10 bg-white shadow-spread"
      >
        {globals?.info && (
          <FlashInfo
            node={globals.info}
            visibility={showFlashInfo}
            visibilityHandler={setShowFlashInfo}
          />
        )}

        <SearchBar
          show={showSearch}
          close={() => setShowSearch(false)}
          autoFocus
        />

        <div className="container px-4 mx-auto">
          <div className="flex">
            <div className="flex-1 py-6 leading-none">
              <Branding
                home={home}
                size={scrollY > threshold ? "small" : "default"}
              />
            </div>
            <div className="flex-initial">
              <Navbar
                globals={globals}
                search={showSearch}
                searchHandler={setShowSearch}
              />
            </div>
          </div>
        </div>
      </Transition>

      {hasBanners > 0 && (
        <div className="relative h-full">
          <NodeBannerList nodes={banners} />
        </div>
      )}
    </header>
  );
}
