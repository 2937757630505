import React from "react";

export default function DocumentIcon({ className }) {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g fill="currentColor" fillRule="nonzero">
        <path
          opacity=".3"
          d="M13.196.767H.769l12.427 20.466h6.035V5.883l-6.035 1.122z"
        />
        <path d="M20 22H0V0h14.421L20 5.566V22ZM12.307 1.535H1.538v18.93h16.924V7.674h-6.154v-6.14Zm1.54.062V6.14h4.552l-4.553-4.543Z" />
      </g>
    </svg>
  );
}
