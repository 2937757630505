import React from "react";

export default function UserIcon({ className }) {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.618 12.505h8.764l.409.004c.67.01.938.045 1.294.146a4 4 0 0 1 2.764 2.763l.043.164.034.162c.065.359.074.786.074 1.81v1.45a1 1 0 0 1-2 0l-.003-2.133-.006-.318c-.01-.318-.03-.463-.066-.592a2 2 0 0 0-1.383-1.382l-.094-.022-.124-.02c-.163-.02-.4-.028-.825-.031l-8.825-.001c-.794.003-1.023.02-1.217.074a2 2 0 0 0-1.382 1.383l-.022.094-.02.124c-.02.163-.028.4-.031.825L2 19.004a1 1 0 0 1-2 0v-1.882l.005-.409c.01-.67.045-.938.146-1.294a4 4 0 0 1 2.763-2.764l.164-.043.176-.037c.3-.053.653-.067 1.364-.07ZM9 0C6.135 0 3.8 2.26 3.8 5.064c0 2.805 2.335 5.065 5.2 5.065s5.2-2.26 5.2-5.065C14.2 2.26 11.865 0 9 0Zm0 2c1.775 0 3.2 1.38 3.2 3.064 0 1.685-1.425 3.065-3.2 3.065-1.775 0-3.2-1.38-3.2-3.065S7.225 2 9 2Z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
}
