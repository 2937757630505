import React from "react";

export default function StructureIcon({ className }) {
  return (
    <svg
      width="29"
      height="30"
      viewBox="0 0 29 30"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g fill="currentColor" fillRule="evenodd">
        <path
          opacity=".3"
          d="M14.501 28.95.255 22.194l.987-1.82-.987-5.089.987-1.82L0 8.095 14.501 15z"
        />
        <path
          d="m27.758 20.373.987 1.82L14.5 30 .255 22.193l.987-1.82L14.5 27.64l13.258-7.266Zm0-6.908.987 1.82L14.5 23.09.255 15.284l.987-1.82L14.5 20.73l13.258-7.265ZM14.5 0 29 8.094l-14.5 8.094L0 8.094 14.5 0Zm0 2.371L4.247 8.094 14.5 13.817l10.252-5.723L14.5 2.371Z"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}
