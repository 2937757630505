import React from "react";

export default function HeadsetIcon({ className }) {
  return (
    <svg
      width="31"
      height="32"
      viewBox="0 0 31 32"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g fill="currentColor" fillRule="evenodd">
        <path
          d="M15.5 2c5.375 0 9.733 4.317 9.733 9.643l-.001 1.785H31v10.715h-5.852c-.463 2.889-2.766 5.171-5.682 5.63V32h-7.931v-6.429h7.93v2.019c2.067-.484 3.605-2.323 3.605-4.519V11.643c0-4.142-3.39-7.5-7.57-7.5s-7.57 3.358-7.57 7.5v12.5H0V13.429l5.767-.001v-1.785C5.767 6.317 10.125 2 15.5 2Zm1.802 25.714h-3.604v2.143h3.604v-2.143ZM5.767 15.571H2.163V22h3.604v-6.429Zm23.07 0h-3.604V22h3.604v-6.429Z"
          fillRule="nonzero"
        />
        <path
          d="M6.985 8.894c5.622.409 9.94 1.812 12.951 4.208 3.012 2.396 4.417 4.854 4.215 7.373l-1.081-8.832c.506-1.808.555-3.21.147-4.205-.407-.995-1.625-2.38-3.652-4.158-2.734-.097-4.687-.022-5.858.224-1.17.246-2.005.578-2.504.996L6.985 8.894Z"
          opacity=".3"
        />
      </g>
    </svg>
  );
}
