import React, { useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import classNames from "lib/classNames";
import Link from "next/link";
import i18nConfig from "i18n.json";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";

export default function LocalesDropdown() {
  const { locales } = i18nConfig;
  const { lang } = useTranslation();
  const [isOpen, setOpen] = useState(false);

  return (
    <Menu as="div" className="relative">
      <>
        <Menu.Button
          className={classNames(
            "flex items-center h-full hover:text-primary transition-colors",
            isOpen && "bg-secondary-light"
          )}
          onClick={() => setOpen((oldOpen) => !oldOpen)}
        >
          <span className="flex items-center gap-2 px-8 border-l border-secondary">
            <Image
              src={`/images/flags/${lang}.png`}
              width={15}
              height={11}
              layout="intrinsic"
              alt={`${lang} flag`}
              className="rounded-sm border border-secondary"
            />
            <span className="uppercase text-sm font-important font-medium">
              {lang}
            </span>
            <ChevronDownIcon
              className={classNames(
                "h-4 w-4 text-primary transition-all",
                isOpen && "-rotate-180"
              )}
              aria-hidden="true"
            />
          </span>
        </Menu.Button>
        <Transition
          show={isOpen}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Menu.Items
            as="ul"
            className="flex flex-col absolute inset-x-0 text-right bg-secondary-light z-50 "
            static
          >
            {locales.map((lng) => {
              if (lng === lang) return null;

              return (
                <Menu.Item as="li" key={lng}>
                  {({ active }) => (
                    <Link href="/" locale={lng} prefetch={false} passHref>
                      <a
                        className={classNames(
                          "flex items-center gap-2 py-3 px-8 uppercase text-black",
                          active && "bg-secondary-light"
                        )}
                        onClick={() => setOpen(false)}
                      >
                        <Image
                          src={`/images/flags/${lng}.png`}
                          width={15}
                          height={13}
                          layout="intrinsic"
                          alt={`${lng} flag`}
                          className="rounded-sm border border-secondary"
                        />
                        <span className="uppercase text-sm font-important font-medium">
                          {lng}
                        </span>
                      </a>
                    </Link>
                  )}
                </Menu.Item>
              );
            })}
          </Menu.Items>
        </Transition>
      </>
    </Menu>
  );
}

export function LocalesMenu() {
  const { locales } = i18nConfig;
  const { lang } = useTranslation();

  return (
    <ul className="flex gap-2">
      {locales.map((lng) => {
        return (
          <li key={lng}>
            <div
              className={classNames(
                "flex justify-center items-center overflow-hidden rounded-full p-3",
                lng === lang ? "bg-secondary" : "bg-secondary"
              )}
            >
              <Link href="/" locale={lng} prefetch={false} passHref>
                <a className="flex justify-center items-center uppercase text-white h-5 w-5">
                  {lng}
                </a>
              </Link>
            </div>
          </li>
        );
      })}
    </ul>
  );
}
