import React, { useContext, useState } from "react";
import Link from "next/link";
import { Menu, Transition } from "@headlessui/react";
import classNames from "lib/classNames";
import { ScrollContext } from "contexts/ScrollContext";
import { ChevronDownIcon } from "@heroicons/react/outline";
import NextLink from "components/NextLink";
import useTranslation from "next-translate/useTranslation";

export default function MainMenu({ menu, closeCallback = () => null }) {
  return menu && <MainMenuItems menu={menu} closeCallback={closeCallback} />;
}

function MainMenuItems({ menu, closeCallback }) {
  const { lang } = useTranslation();
  const [active, setActive] = useState();

  return (
    <ul className="flex flex-col xl:flex-row">
      {menu.map((link) => {
        if (link.langcode !== lang) return null;

        const hasDropdown = link.items?.length > 0;

        return (
          <li key={link.url} className="relative">
            {hasDropdown ? (
              <MainMenuItemDropdown
                item={link}
                active={active}
                activeCallback={setActive}
                closeCallback={closeCallback}
              />
            ) : (
              <NextLink
                href={link.url}
                className="inline-flex items-center h-full py-2 xl:p-6 2xl:p-8 font-important font-medium text-xl xl:text-sm xl:uppercase xl:hover:text-primary transition-colors"
                onClick={closeCallback}
              >
                <span>{link.title}</span>
              </NextLink>
            )}
          </li>
        );
      })}
    </ul>
  );
}

function MainMenuItemDropdown({ item, active, activeCallback, closeCallback }) {
  const { lang } = useTranslation();
  const isActive = active && active.id === item.id;
  const { scrollY, threshold } = useContext(ScrollContext);

  return (
    <Menu>
      {({ open }) => (
        <>
          <Menu.Button
            className={classNames(
              "inline-flex gap-2 items-center h-full py-2 xl:p-6 2xl:p-8 font-important font-medium text-xl xl:text-sm xl:uppercase xl:hover:text-primary transition-colors",
              open && "xl:bg-secondary-light"
            )}
            onClick={() => activeCallback(isActive ? null : { id: item.id })}
          >
            {item.title}
            <ChevronDownIcon
              className={classNames(
                "h-5 w-5 xl:hidden transition-transform",
                open && "-rotate-180"
              )}
              aria-hidden="true"
            />
          </Menu.Button>
          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Menu.Items
              className={classNames(
                "flex flex-col xl:absolute left-0 xl:w-96 xl:px-8 xl:py-5 xl:bg-secondary-light xl:shadow-spread",
                scrollY > threshold ? "xl:-mt-6" : "xl:-mt-8"
              )}
              static
            >
              {item.items.map((subItem) => {
                if (subItem.langcode !== lang) return null;

                return (
                  <Menu.Item key={subItem.url}>
                    <NextLink
                      href={subItem.url}
                      className="block text-white/70 xl:text-default uppercase font-important font-medium text-sm py-2 xl:py-3 transition-colors xl:hover:text-primary"
                      onClick={() => {
                        activeCallback(null);
                        closeCallback();
                      }}
                    >
                      {subItem.title}
                    </NextLink>
                  </Menu.Item>
                );
              })}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}
