import React, { forwardRef } from "react";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";
import i18nConfig from "i18n.json";

export function removeLangFromHref(href, lang = null) {
  const { defaultLocale } = i18nConfig;

  if (lang !== defaultLocale && href.startsWith(`/${lang}`)) {
    return href.slice(lang.length + 1);
  }

  return href;
}

const NextLink = forwardRef(({ href, children, ...props }, ref) => {
  const { lang } = useTranslation();
  const hrefWithoutLng = removeLangFromHref(href, lang);

  return (
    <Link href={hrefWithoutLng || "/"} locale={false} prefetch={false} passHref>
      <a ref={ref} {...props}>
        {children}
      </a>
    </Link>
  );
});

export default NextLink;
