import React from "react";
import useTranslation from "next-translate/useTranslation";
import { show } from "cookie-though";
import NextLink from "components/NextLink";

export default function FooterMenu({ menu }) {
  const { t, lang } = useTranslation("common");

  return (
    menu && (
      <ul className="flex flex-col lg:flex-row gap-4 text-sm">
        {menu.map((item) => {
          if (item.langcode !== lang) return null;

          return (
            <li key={item.id}>
              <NextLink
                href={item.url}
                className="text-default font-important hover:text-primary transition-colors"
              >
                {item.title}
              </NextLink>
            </li>
          );
        })}
        <li>
          <button
            type="button"
            onClick={() => show()}
            className="text-default font-important hover:text-primary transition-colors"
          >
            {t("utils.cookies")}
          </button>
        </li>
      </ul>
    )
  );
}
