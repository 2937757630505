import React from "react";

export default function KeyboardIcon({ className }) {
  return (
    <svg
      width="28"
      height="25"
      viewBox="0 0 28 25"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g fill="currentColor" fillRule="evenodd">
        <path opacity=".3" d="M1 9h12L8.707 24H1z" />
        <path
          d="M21.488 0v3.807l-6.511 2.531V7.91L28 7.911V25H0V7.911h13.023V5.053l6.512-2.533V0h1.953Zm4.559 9.81H1.953v13.291h24.094V9.811ZM7.814 18.038v1.899H4.558v-1.899h3.256Zm15.628 0v1.899h-3.256v-1.899h3.256Zm-5.21 0v1.899H9.768v-1.899h8.466ZM7.815 12.975v1.898H4.558v-1.898h3.256Zm5.21 0v1.898H9.766v-1.898h3.256Zm10.418 0v1.898h-3.256v-1.898h3.256Zm-5.21 0v1.898h-3.255v-1.898h3.256Z"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}
