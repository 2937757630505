import React, { createRef } from "react";
import dynamic from "next/dynamic";
import Header from "components/Header";
import Footer from "components/Footer";

const PreviewAlert = dynamic(() => import("components/PreviewAlert"));
const ReinsuranceBlock = dynamic(() =>
  import("components/Block/ReinsuranceBlock")
);

export default function Layout({ children, globals, banners, home = false }) {
  const mainRef = createRef();

  return (
    <>
      <PreviewAlert />

      <Header globals={globals} banners={banners} home={home} />

      <main ref={mainRef} className="relative">
        {children}
      </main>

      {globals.blocks?.reinsurance && (
        <ReinsuranceBlock data={globals.blocks.reinsurance} />
      )}

      <Footer globals={globals} />
    </>
  );
}
