import React from "react";

export default function MailboxIcon({ className }) {
  return (
    <svg
      width="24"
      height="30"
      viewBox="0 0 24 30"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g fill="currentColor" fillRule="nonzero">
        <path
          d="M1.029 10.69c0-3.047 2.456-5.518 5.485-5.518H12v13.794H1.029V10.69Z"
          fillOpacity=".3"
        />
        <path d="M18.514 0v4.138H24V20H13.029v10H10.97V20H0v-9.31C0 7.07 2.917 4.138 6.514 4.138h9.943V2.069h-3.086V0h5.143Zm3.429 6.207h-3.429v5.862h-2.057V6.207H6.514c-2.376 0-4.319 1.87-4.45 4.228l-.007.255v7.241h19.886V6.207Z" />
      </g>
    </svg>
  );
}
