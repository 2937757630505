import React from "react";

export default function ArchiveCheckIcon({ className }) {
  return (
    <svg
      width="26"
      height="22"
      viewBox="0 0 26 22"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g fill="currentColor" fillRule="evenodd">
        <path opacity=".3" d="M3 6h9v15H3z" />
        <path
          d="M26 0v7.097h-1.333V22H1.333V7.097H0V0h26Zm-3.333 7.097H3.333v12.968h19.334V7.097Zm-6.183 2.68 1.561 1.209-5.26 6.473-4.452-3.693 1.302-1.47 2.88 2.39 3.969-4.91ZM12 1.935H2v3.226h10V1.935Zm12 0H14v3.226h10V1.935Z"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}
