import React from "react";

export default function EnvelopeIcon({ className }) {
  return (
    <svg
      width="30"
      height="24"
      viewBox="0 0 30 24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g fill="currentColor" fillRule="nonzero">
        <path
          fillOpacity=".3"
          d="m1.047 1.029.552 4.776 14.606 8.033-14.112 8.525-1.046.608z"
        />
        <path d="M30 0v24H0V0h30Zm-2.093 6.959L15 14.57 2.093 6.96v14.983h25.814V6.959Zm0-4.902H2.093V4.56L15 12.171 27.907 4.56V2.057Z" />
      </g>
    </svg>
  );
}
