import React from "react";

export default function QuoteIcon({ className }) {
  return (
    <svg
      width="40px"
      height="29px"
      viewBox="0 0 40 29"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-483.000000, -2837.000000)"
          fill="currentColor"
          fillRule="nonzero"
        >
          <g transform="translate(413.000000, 2767.000000)">
            <path d="M94.9996504,71 L94.9996504,97.5858692 C94.9996504,98.138154 95.4473657,98.5858692 95.9996504,98.5858692 C96.2648585,98.5858692 96.5192051,98.480519 96.7067401,98.2929932 L109.70709,85.2932741 C109.894637,85.1057363 110,84.851375 110,84.5861501 L110,71.0007558 C109.99995,70.4484907 109.552265,70.0007836 109,70.0007054 L95.9996504,70.0000504 C95.4473657,69.9999722 94.9996279,70.4476649 94.9996001,70.9999496 C94.9996001,70.9999664 94.9996001,70.9999832 94.9996504,71 Z M70,71 L70,97.5858692 C70,98.138154 70.4477153,98.5858692 71,98.5858692 C71.2652081,98.5858692 71.5195547,98.480519 71.7070896,98.2929932 L84.7074392,85.2932741 C84.8949861,85.1057363 85.0003496,84.851375 85.0003496,84.5861501 L85.0003496,71.0007558 C85.0002992,70.4484907 84.5526146,70.0007836 84.0003496,70.0007054 L71,70.0000504 C70.4477153,69.9999722 69.9999774,70.4476649 69.9999496,70.9999496 C69.9999496,70.9999664 69.9999496,70.9999832 70,71 Z" />
          </g>
        </g>
      </g>
    </svg>
  );
}
