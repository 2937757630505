import Body from "components/Body";
import classNames from "lib/classNames";
import useIsInViewport from "hook/useIsInViewport";
import React, { useRef } from "react";

export default function BasicBlock({
  data,
  showTitle = false,
  animate = false,
}) {
  const ref = useRef(null);
  const isInViewport = useIsInViewport(ref);
  const transitionDuration = 0.5;
  const transitionDelay = transitionDuration * 0.75;

  return (
    <div ref={ref}>
      <h2
        className={classNames(
          showTitle ? "text-4xl font-important mb-8" : "sr-only",
          animate ? "transition-all" : "transition-none"
        )}
        style={{
          opacity: isInViewport ? 100 : 0,
          transform: `translateX(${isInViewport ? 0 : "-1.5rem"})`,
          transitionDuration: `${transitionDuration}s`,
          transitionDelay: `${transitionDelay}s`,
        }}
      >
        {data.info}
      </h2>
      <div
        className={classNames(
          "prose max-w-none text-sm",
          animate ? "transition-all" : "transition-none"
        )}
        style={{
          opacity: isInViewport ? 100 : 0,
          transform: `translateX(${isInViewport ? 0 : "-1.5rem"})`,
          transitionDuration: `${transitionDuration}s`,
          transitionDelay: `${transitionDelay + 0.2}s`,
        }}
      >
        <Body value={data.body.processed} />
      </div>
    </div>
  );
}
