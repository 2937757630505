import React, { useEffect, useMemo, useState } from "react";

export default function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(() => {
    return typeof window !== "undefined"
      ? new IntersectionObserver(([entry]) => {
          if (entry.isIntersecting) {
            setIsIntersecting(entry.isIntersecting);
          }
        })
      : null;
  }, []);

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}
