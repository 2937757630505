import React from "react";

export default function NetworkIcon({ className }) {
  return (
    <svg
      width="28"
      height="27"
      viewBox="0 0 28 27"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g fill="currentColor" fillRule="evenodd">
        <ellipse opacity=".3" cx="14" cy="4.655" rx="3.733" ry="3.724" />
        <ellipse opacity=".3" cx="4.2" cy="22.81" rx="3.267" ry="3.259" />
        <ellipse opacity=".3" cx="23.8" cy="22.81" rx="3.267" ry="3.259" />
        <path
          d="M14 0c2.46 0 4.455 1.968 4.455 4.395 0 2.104-1.498 3.863-3.5 4.294v6.518l5.776 3.99a4.473 4.473 0 0 1 2.814-.988c2.46 0 4.455 1.968 4.455 4.396C28 25.032 26.006 27 23.545 27c-2.46 0-4.454-1.968-4.454-4.395 0-.692.162-1.347.45-1.93L14 16.848l-5.54 3.829a4.33 4.33 0 0 1 .45 1.929C8.91 25.032 6.915 27 4.455 27S0 25.032 0 22.605c0-2.428 1.994-4.396 4.455-4.396 1.067 0 2.046.37 2.814.988l5.776-3.992V8.69c-1.924-.415-3.384-2.057-3.493-4.053l-.007-.242C9.545 1.968 11.54 0 14 0ZM4.455 20.093c-1.406 0-2.546 1.125-2.546 2.512s1.14 2.511 2.546 2.511C5.86 25.116 7 23.992 7 22.605s-1.14-2.512-2.545-2.512Zm19.09 0c-1.405 0-2.545 1.125-2.545 2.512s1.14 2.511 2.545 2.511c1.406 0 2.546-1.124 2.546-2.511s-1.14-2.512-2.546-2.512ZM14 1.883c-1.406 0-2.545 1.125-2.545 2.512S12.595 6.907 14 6.907c1.406 0 2.545-1.125 2.545-2.512S15.405 1.884 14 1.884Z"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}
