import React from "react";

export default function ClockIcon({ className }) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g fill="currentColor" fillRule="evenodd">
        <path
          d="M18.533 4.928c1.348 5.473 1.348 10.012 0 13.617-1.348 3.605-3.793 6.733-7.335 9.386l8.154.136c1.876-.078 3.224-.464 4.044-1.16.82-.694 1.6-2.111 2.34-4.25l2.024-7.5c-.753-2.63-1.428-4.464-2.024-5.502-.596-1.037-1.17-1.729-1.72-2.074l-5.483-2.653Z"
          opacity=".3"
        />
        <path
          d="M16.02 0v3.143c6.846.528 12.239 6.33 12.239 13.409C28.259 23.979 22.323 30 15 30S1.741 23.979 1.741 16.552c0-7.08 5.393-12.881 12.24-13.41V0h2.039ZM15 5.172c-6.196 0-11.22 5.095-11.22 11.38C3.78 22.836 8.805 27.93 15 27.93c6.196 0 11.22-5.095 11.22-11.38 0-6.284-5.024-11.379-11.22-11.379Zm1.02 4.483v5.862h3.74v2.07h-5.78V9.654h2.04ZM23.88.648 30 6.855l-1.442 1.463-6.12-6.207L23.881.648Zm-17.76 0L7.561 2.11l-6.12 6.207L0 6.855 6.12.648Z"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}
