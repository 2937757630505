import React from "react";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import Link from "next/link";
import getSocialFromUrl from "lib/getSocialFromUrl";
import { HeroIconFromClass } from "components/HeroIcon";
import FacebookIcon from "components/Icons/FacebookIcon";
import InstagramIcon from "components/Icons/InstagramIcon";
import LinkedinIcon from "components/Icons/LinkedinIcon";
import TwitterIcon from "components/Icons/TwitterIcon";
import useTranslation from "next-translate/useTranslation";
import NextLink from "components/NextLink";

export default function SocialNetworksMenu({ menu }) {
  const { lang } = useTranslation();

  const components = {
    facebook: FacebookIcon,
    instagram: InstagramIcon,
    linkedin: LinkedinIcon,
    twitter: TwitterIcon,
    other: ExternalLinkIcon,
  };

  return (
    <ul className="flex flex-col gap-4">
      {menu.map((link) => {
        if (link.langcode !== lang) return null;

        const hasHeroIcon = link.options?.attributes["data-icon-name"] || false;
        const social = getSocialFromUrl(link.url);
        const IconComponent = components[social];

        return (
          <li key={link.title}>
            <NextLink
              href={link.url}
              className="group flex items-center gap-2 transition-colors text-sm"
              target="blank"
              rel="noreferrer"
            >
              <span className="block p-2 bg-primary rounded-full group-hover:bg-primary-dark transition-colors">
                {hasHeroIcon ? (
                  <HeroIconFromClass
                    name={link.options.attributes["data-icon-name"]}
                    type={link.options.attributes["data-icon-type"]}
                    className="w-4 h-4 text-white"
                  />
                ) : (
                  <IconComponent className="w-4 h-4 text-white" />
                )}
              </span>
              <span className="font-important group-hover:text-primary transition-colors">
                {link.title}
              </span>
            </NextLink>
          </li>
        );
      })}
    </ul>
  );
}
