import React from "react";

export default function ScrollIcon({ className }) {
  return (
    <svg
      width="25"
      height="29"
      viewBox="0 0 25 29"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g fill="currentColor" fillRule="nonzero">
        <path opacity=".3" d="M7.98 19.584v8.488H1.106V1H7.98z" />
        <path d="M25 0v10.116h-5.395V29H.047V0H25Zm-2.023 2.023H2.07v24.954H17.58V4.384h2.024v3.709h3.372v-6.07Zm-8.43 11.465v2.024H5.105v-2.024h9.442Zm-4 5.392v2.023H5.105V18.88h5.442Zm4-10.787v2.023H5.105V8.093h9.442Z" />
      </g>
    </svg>
  );
}
